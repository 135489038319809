/*
    C-POS EXTENSION
    Extension du composant générique c-pos
    https://github.com/ita-design-system/c-pos.scss
    Ce fichier doit servir à étendre ou surcharger les fonctionnalités
    du composant c-pos selon les besoins du projet
*/
$briks-components-generic: (
    // Nom du composant, obligatoirement pos
    pos: ( 
        // Extension activée true ou false
        enabled: true, 
        // Responsive activée true ou false pour l'extension
        responsive: true, 
        // Valeurs par défaut de l'extension
        defaults: (),
        // Liste des modifieurs contenant chacun une liste de propriétés qui 
        // soit surchargent les propriétés par défaut
        // soit ajoutent des propriétés
        // soit les deux
        modifiers: ( 
            // Ajout de valeurs de propriétés liées aux design tokens de positionnements
            top-380px: (
                top: 380px
            ),
            top-130px: (
                top: 130px
            ),
            top--20px: (
                top: -20px
            ),
            z-1000: (
                z-index: 1000
            ),
            z-1100: (
                z-index: 1100
            ),
            z-1300: (
                z-index: 1300
            ),
            z-1400: (
                z-index: 1400
            ),
            z-1500: (
                z-index: 1500
            ),
            z-1600: (
                z-index: 1600
            ),
            z-1700: (
                z-index: 1700
            ),
            z-1800: (
                z-index: 1800
            ),
            z-1900: (
                z-index: 1900
            ),
            z-2000: (
                z-index: 2000
            ),
            z-2100: (
                z-index: 2100
            ),
            z-2200: (
                z-index: 2200
            ),
            z-2300: (
                z-index: 2300
            ),
            z-2400: (
                z-index: 2400
            ),
            z-2500: (
                z-index: 2500
            ),
            z-2600: (
                z-index: 2600
            ),
            z-2700: (
                z-index: 2700
            ),
            z-2800: (
                z-index: 2800
            ),
            z-2900: (
                z-index: 2900
            )
        )
    )
);